import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ResponseHelperService} from './response-helper.service';
import {EventManagerService} from './event-manager.service';
import {StorageService} from './storage.service';
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {isPlatformBrowser} from "@angular/common";
import {Observable} from "rxjs";


interface HeaderObjType {
  "language"?:string,
  "currency"?:string,
  "Authorization"?:string,
}
@Injectable()
export class HttpService {


  static requestQueue = [];
  static eventManager: any;
  static notificationService: any;


  constructor( private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
                @Inject(PLATFORM_ID) private platformId: any,
              public eventManager: EventManagerService,
  ) {

  }

  setHttpOptions(token: string|undefined): HttpHeaders {
    const headerObj:HeaderObjType | any = {};
    if (StorageService.getItem(StorageService.statics.lang)) {
      headerObj['language'] = StorageService.getItem(StorageService.statics.lang);
    }
    if (StorageService.getItem(StorageService.statics.currency)) {
      headerObj['currency'] = StorageService.getItem(StorageService.statics.currency);
    }
    if (StorageService.getJsonItem(StorageService.statics.publisher)?._id) {
      headerObj['publisherid'] = StorageService.getJsonItem(StorageService.statics.publisher)?._id;
    }
    if (StorageService.getToken()) {
      headerObj['Authorization'] = environment.authHeaderPrefix + StorageService.getToken().toString() + environment.authHeaderSufix;
    }
    if(token){
      headerObj['Authorization'] = environment.authHeaderPrefix + token + environment.authHeaderSufix;
    }
    if(token === 'noToken'){
      delete headerObj['Authorization'];
    }
    return new HttpHeaders(headerObj);
  }

  public checkQueue(): void {
    const one = 1 as never ;
    HttpService.requestQueue.push(one);
    if (HttpService.requestQueue.length === 1) {
      // this.eventManager.showLoader();
    }
  }

  public concatGetParams(obj:any): any {
    let num = 0;
    let paramsUrl:any = null;

    for (let [key, value] of Object.entries(obj)) {
      if(value !== null && value !== undefined){
        let isArray = false
        if(key.slice(-2) === '[]'){
          isArray = true
          //@ts-ignore
          if(value.length < 1){
            continue
          }
        }
        if (num === 0) {
          if(isArray){
            for (let i = 0; i < obj[key].length; i ++){
              if(i === 0){
                paramsUrl = `?${key}=${encodeURIComponent(obj[key][i])}`;
              }else {
                paramsUrl += `&${key}=${encodeURIComponent(obj[key][i])}`;
              }
            }
          }else {
            //@ts-ignore
            paramsUrl = `?${key}=${encodeURIComponent(value)}`;
          }
        } else {
          if(isArray){
            for (let i = 0; i < obj[key].length; i ++){
                paramsUrl += `&${key}=${encodeURIComponent(obj[key][i])}`
            }
          }else {
            //@ts-ignore
            paramsUrl += `&${key}=${encodeURIComponent(value)}`;
          }
        }
        num++;
      }

    }
    return paramsUrl;
  }

  get<T>(url:string, jsonData?:object, customToken?: string, withSSR = false): Observable<ResponseHelperService> {
    if(isPlatformBrowser(this.platformId) || withSSR){
      const headers: any = this.setHttpOptions(customToken);
      this.checkQueue();
      let getParms = jsonData === undefined || null ? '' : this.concatGetParams(jsonData);
      return this.formatedResponse(this.http.get<any>(url + (getParms || url.indexOf('?') > -1 ? getParms : ''), {headers}));
    }
    return this.formatedResponse(new Observable(()=>{}))
  }


  getWithSSR<T>(url:string, jsonData?:object, customToken?: string): Observable<ResponseHelperService> {
      const headers: any = this.setHttpOptions(customToken);
      this.checkQueue();
      let getParms = jsonData === undefined || null ? '' : this.concatGetParams(jsonData);
      return this.formatedResponse(this.http.get<any>(url + (getParms?getParms:''), {headers}));
  }

  //
  // getWithoutLoading<T>(url, jsonData?, customToken?: string): Observable<ResponseHelperService>{
  //   const headers: any = this.setHttpOptions(customToken);
  //   const getParms = jsonData === undefined ? '' : this.concatGetParams(jsonData);
  //   return this.formatedResponse(this.http.get<any>(url + getParms, {headers}));
  // }
  //
  post<T>(url: string, data?: object, token?: string): Observable<ResponseHelperService> {
    const headers = this.setHttpOptions(token);
    this.checkQueue();
    return this.formatedResponse(this.http.post(url, data, {headers}));
  }

  put<T>(url: string, data: any, customToken?: string): Observable<ResponseHelperService> {
    this.checkQueue();
    const headers: any = this.setHttpOptions(customToken);
    return this.formatedResponse(this.http.put<T>(url, data, {headers}));
  }

  del<T>(url: string, customToken?: string): Observable<ResponseHelperService> {
    this.checkQueue();
    const headers: any = this.setHttpOptions(customToken);
    return this.formatedResponse(this.http.delete<T>(url, {headers}));
  }

  formatedResponse(obj:any): any {
    return obj?.pipe(catchError(this.handleError))?.pipe(map((res: any) => {
      if (res.status == 401) {
        StorageService.clear();
        this.router.navigate(['auth/signin']);
        //this.router.navigate(['/under-construction']);
      } else if (res.status == 403) {
        this.router.navigate(['/403']);
      }

      HttpService.requestQueue.splice(0, 1);

      if (HttpService.requestQueue.length === 0) {
        // this.eventManager.hideLoader();
      }
      return new ResponseHelperService(res);
    }));
  }

  private handleError(error: HttpErrorResponse): any {

    // if (error.error instanceof ErrorEvent) {
    //
    //   // A client-side or network error occurred. Handle it accordingly.
    // } else {
    //   // The backend returned an unsuccessful response code.
    //   // The response body may contain clues as to what went wrong,
    // }
    // NetworkHelper.popupHelper.printError('something went wrong. Please check your network connection');
    // return an observable with a user-facing error message
    HttpService.requestQueue.splice(0, 1);
    if (HttpService.requestQueue.length === 0) {
      // HttpService.eventManager.hideLoader();
    }

    HttpService.notificationService?.printError('something_went_wrong');
    return ;
  }

}

import { Injectable } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import { Router} from "@angular/router";

export interface IMetaTags{
  title?: string,
  description?: string,
  keywords?: string,
  url?: string,
  cardImage?: string,
  image?: string,
}

@Injectable({
  providedIn: 'root',
})
export class UpdateMetaTags {
  constructor(
      private titleService: Title,
      private metaService: Meta,
      private router: Router) {

  }

  updateMetaTags(data: IMetaTags = {}) {
    if (!data.title) {
      data.title = 'Play Free Rewarded Tournaments | Cybfed Esports Platform';
    }
    if (!data.description) {
      data.description = 'Join Cybfed\'s free rewarded tournaments and compete for prizes. Play for free, win rewards, and boost your esports career on the ultimate gaming platform.';
    }
    if (!data.keywords) {
      data.keywords = 'free rewarded tournaments, top, team tournament, favorite games, esports organization, game developers, hobby profitable, tournaments, create, unlock, game, esports, community, team, play for free, esports tournaments, gaming prizes, compete for rewards, free tournaments for gamers, free entry tournaments, competitive gaming, win prizes, esports platform, Cybfed tournaments';
    }
    if (!data.image) {
      data.image = '/assets/image/cybfed-logo.png';
    }
    if (!data.cardImage) {
      data.cardImage = '/assets/hero-banner/cybfed-webp';
    }
    // Example of adding Open Graph tags
    this.titleService.setTitle(data.title);
    this.metaService.updateTag({ name: 'description', content: data.description });
    this.metaService.updateTag({ name: 'keywords', content: data.keywords });
    this.metaService.updateTag({ property: 'og:title', content: data.title});
    this.metaService.updateTag({ property: 'og:description', content: data.description});
    this.metaService.updateTag({ name: 'twitter:title', content: data.title });
    this.metaService.updateTag({ name: 'twitter:description', content: data.description });
    this.metaService.updateTag({ property: 'og:url', content: this.router.url });


    this.metaService.updateTag({ property: 'og:image', content: data.image });
    this.metaService.updateTag({ name: 'twitter:card', content:  data.cardImage });
    this.metaService.updateTag({ name: 'twitter:image', content: data.image });
    //console.log(data, from);
  }

}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class TeamsService {
  private getMyTeamsUrl = '/teams/my-teams'
  private getItemsUrl = '/teams/list'
  private getInviteItemsUrl = '/teams/invite-list'
  private deleteUrl = '/teams/:id'
  private addUrl = '/teams'
  private getByIdUrl = '/teams/:id'
  private getByDomainUrl = '/teams/by-domain/:id'
  private updateUrl = '/teams/:id'
  private sendInvitationToUserUrl = '/teams/send-invite'
  private deleteInviteUrl = '/teams/delete-invite/:inviteId'
  private cancelInviteUrl = '/teams/cancel-invite/:inviteId'
  private acceptInviteUrl = '/teams/accept-invite/:inviteId'
  private acceptInviteByLinkUrl = '/teams/accept-invite-link'
  private removeInTeamUrl = '/teams/remove/:id'
  private makeMainPlayerUrl = '/teams/make-main-player/:id'
  private makeReservePlayerUrl = '/teams/make-reserve-player/:id'

  constructor(private networkHelper: HttpService) {
  }

  getItems(query: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getItemsUrl, query);
  }

  getMyTeams(query: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getMyTeamsUrl, query);
  }

  delete(id: string): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.deleteUrl.replace(':id', id))
  }

  add(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.addUrl, data)
  }

  getById(id: any): Observable<any> {
    return this.networkHelper.getWithSSR(environment.apiURL + this.getByIdUrl.replace(':id', id))
  }

  getByDomain(domain: any): Observable<any> {
    return this.networkHelper.getWithSSR(environment.apiURL + this.getByDomainUrl.replace(':id', domain))
  }

  sendInvitationToUser(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.sendInvitationToUserUrl, data)
  }

  deleteInvite(inviteId: any): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.deleteInviteUrl.replace(':inviteId', inviteId))
  }
  getInviteItems(query: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getInviteItemsUrl, query)
  }
  cancelInvite(inviteId: string): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.cancelInviteUrl.replace(':inviteId', inviteId), {})
  }
  acceptInvite(inviteId: string): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.acceptInviteUrl.replace(':inviteId', inviteId), {})
  }
  removeInTeam(id: string, accountId: string): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.removeInTeamUrl.replace(':id', id), {accountId})
  }
  update(id: string, data: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.updateUrl.replace(':id', id), data)
  }
  makeMainPlayer(id: string, data: any){
    return this.networkHelper.post(environment.apiURL + this.makeMainPlayerUrl.replace(':id', id), data)
  }
  makeReservePlayer(id: string, data: any){
    return this.networkHelper.post(environment.apiURL + this.makeReservePlayerUrl.replace(':id', id), data)
  }


  acceptSimpleInviteByLink(teamId:any, pEx: any){
    return this.networkHelper.post(environment.apiURL + this.acceptInviteByLinkUrl, {teamId, pEx})
  }
}


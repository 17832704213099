import { Injectable , EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {

  public menuTrigger: EventEmitter<any> = new EventEmitter();
  public openDisputeModalEmitter: EventEmitter<any> = new EventEmitter();


  public userCard: EventEmitter<any> = new EventEmitter();
  public rerenderEvent: EventEmitter<any> = new EventEmitter();

  public socketDataListener: EventEmitter<any> = new EventEmitter();

  public loaderShowEmitter: EventEmitter<any> = new EventEmitter();
  public participateService: EventEmitter<any> = new EventEmitter();
  public updateHomePage: EventEmitter<any> = new EventEmitter();
  public loaderHideEmitter: EventEmitter<any> = new EventEmitter();
  public changeUserEmitter: EventEmitter<any> = new EventEmitter();
  public betOnMeBetModalEmitter: EventEmitter<any> = new EventEmitter();
  public showLoginModal: EventEmitter<any> = new EventEmitter();
  public showRegisterModal: EventEmitter<any> = new EventEmitter();
  public changeRoomMessageComponentEmitter: EventEmitter<any> = new EventEmitter();
  public updateTasksCount: EventEmitter<any> = new EventEmitter();
  public initialInfoEmitter: EventEmitter<any> = new EventEmitter();
  public addToBetOnMeChatEmitter: EventEmitter<any> = new EventEmitter();
  public setLoadMoreFilterEmitter: EventEmitter<any> = new EventEmitter();
  public setLoadMoreDataEmitter: EventEmitter<any> = new EventEmitter();
  public triggerStageManagementModal: EventEmitter<any> = new EventEmitter();
  public saveNewTeamEmitter: EventEmitter<any> = new EventEmitter();
  public triggerSendTicketsModal: EventEmitter<any> = new EventEmitter();
  public triggerConvertTicketsModal: EventEmitter<any> = new EventEmitter();
  public triggerEmailVerifyModal: EventEmitter<any> = new EventEmitter();
  public triggerPhoneVerifyModal: EventEmitter<any> = new EventEmitter();
  public openChatWidgetEmitter: EventEmitter<any> = new EventEmitter();
  public showPublisherToAccountLoadingEmitter: EventEmitter<any> = new EventEmitter();
  public hidePublisherToAccountLoadingEmitter: EventEmitter<any> = new EventEmitter();
  public closeOpenChatRoomsEmitter: EventEmitter<any> = new EventEmitter();
  public sendOpenChatIdEmitter: EventEmitter<any> = new EventEmitter();
  public sendCloseChatIdEmitter: EventEmitter<any> = new EventEmitter();
  public showWithPopupNotificationEmitter: EventEmitter<any> = new EventEmitter();
  public deleteCommentEmitter: EventEmitter<any> = new EventEmitter();
  public scrollToTop: EventEmitter<any> = new EventEmitter();
  public openTelegramModalEmitter: EventEmitter<any> = new EventEmitter();
  public openNotificationModalEmitter: EventEmitter<any> = new EventEmitter();
  public openVoteFormEmitter: EventEmitter<any> = new EventEmitter();

  public triggerOnChangePageEmitter: EventEmitter<any> = new EventEmitter();

  public seenRoomMessagesEmitter: EventEmitter<any> = new EventEmitter();
  public teamInvitesCountEmitter: EventEmitter<any> = new EventEmitter();
  public tempTeamInvitesCountEmitter: EventEmitter<any> = new EventEmitter();
  public cancelTeamInviteEmitter: EventEmitter<any> = new EventEmitter();
  public deleteTempTeamInvitationEmitter: EventEmitter<any> = new EventEmitter();
  public acceptTemporaryInviteEmitter: EventEmitter<any> = new EventEmitter();
  public cancelTempTeamInviteByToUserEmitter: EventEmitter<any> = new EventEmitter();
  public cancelTeamInviteByToUserEmitter: EventEmitter<any> = new EventEmitter();
  public acceptTemporaryInviteByToUserEmitter: EventEmitter<any> = new EventEmitter();
  public acceptTeamInviteByToUserEmitter: EventEmitter<any> = new EventEmitter();
  public deleteChatRoomEmitter: EventEmitter<any> = new EventEmitter();
  public openBlockModal: EventEmitter<any> = new EventEmitter();
  public openChatRoomOptionsModalEmitter: EventEmitter<any> = new EventEmitter();
  public taskManagementModalEmitter: EventEmitter<any> = new EventEmitter();
  public selectUserEmitter: EventEmitter<any> = new EventEmitter();
  public cancelSelectedUserEmitter: EventEmitter<any> = new EventEmitter();
  public closeDropDawnOptionsEmitter: EventEmitter<any> = new EventEmitter();
  public openGameInvitationModalEmitter: EventEmitter<any> = new EventEmitter();
  public sendInviteEmitter: EventEmitter<any> = new EventEmitter();
  public deleteInviteEmitter: EventEmitter<any> = new EventEmitter();
  public startLoadInviteButtonToUserEmitter: EventEmitter<any> = new EventEmitter();
  public stopLoadInviteButtonToUserEmitter: EventEmitter<any> = new EventEmitter();
  public stageManagementViewInitEmitter: EventEmitter<any> = new EventEmitter();
  public openFeedBackModalEmitter: EventEmitter<any> = new EventEmitter();
  public openDetailsPopupEmitter: EventEmitter<any> = new EventEmitter();
  public openChangeFieldsPopupEmitter: EventEmitter<any> = new EventEmitter();
  public updateActionForUserActionEmitter: EventEmitter<any> = new EventEmitter();
  public profileDataSatedEmitter: EventEmitter<any> = new EventEmitter();
  public closeNotifyContentEmitter: EventEmitter<any> = new EventEmitter();
  public chooseInParticipatedTeamEmitter: EventEmitter<any> = new EventEmitter();
  public scrollToTeamSectionEmitter: EventEmitter<any> = new EventEmitter();

  public openCopyModalEmitter: EventEmitter<any> = new EventEmitter();
  public openCancelEventEmitter: EventEmitter<any> = new EventEmitter();
  public showToastyEmitter: EventEmitter<any> = new EventEmitter();
  public changeToastyProgressEmitter: EventEmitter<any> = new EventEmitter();
  public hideToastyEmitter: EventEmitter<any> = new EventEmitter();
  public changeOrderStatusEmitter: EventEmitter<any> = new EventEmitter();
  public openOrderDisputeModalEmitter: EventEmitter<any> = new EventEmitter();
  public openReportResultModalEmiter: EventEmitter<any> = new EventEmitter();
  public openParticipateInChallengeModalEmitter: EventEmitter<any> = new EventEmitter();
  public openChallengeRoomDetailsModalEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  showLoader(): void {
    this.loaderShowEmitter.emit();
  }

  hideLoader(): void {
    this.loaderHideEmitter.emit();
  }

  triggerStageManagement(event: any){
    this.triggerStageManagementModal.emit(event);
  }
  saveNewTeam(event: any){
    this.saveNewTeamEmitter.emit(event);
  }

  changeUser(data: any): void{
    this.changeUserEmitter.emit(data)
  }

  betOnMeBetModal(data: any): void{
    this.taskManagementModalEmitter.emit(data)
  }
  changeRoomMessageComponent(message: any){
    this.changeRoomMessageComponentEmitter.emit(message)
  }
  initialInfo(data: any): void{
    this.initialInfoEmitter.emit(data)
  }

  addToBetOnMeChat(participate: any){
    this.addToBetOnMeChatEmitter.emit(participate)
  }

  setLoadMoreFilter(filter: any){
    this.setLoadMoreFilterEmitter.emit(filter)
  }
  setLoadMoreData(data: any){
    this.setLoadMoreDataEmitter.emit(data)
  }

  openChatWidget(data: any){
    this.openChatWidgetEmitter.emit(data)
  }
  showPublisherToAccountLoading(data: any){
    this.showPublisherToAccountLoadingEmitter.emit(data)
  }
  hidePublisherToAccountLoading():void{
    this.hidePublisherToAccountLoadingEmitter.emit()
  }
  closeOpenChatRooms(){
    this.closeOpenChatRoomsEmitter.emit()
  }
  sendOpenChatId(roomId: string){
    this.sendOpenChatIdEmitter.emit(roomId)
  }
  sendCloseChatId(roomId: string){
    this.sendCloseChatIdEmitter.emit(roomId)
  }
  seenRoomMessages(roomId: string){
    this.seenRoomMessagesEmitter.emit(roomId)
  }
  showWithPopupNotification(notification: any){
    this.showWithPopupNotificationEmitter.emit(notification)
  }
  deleteComment(){
    this.deleteCommentEmitter.emit()
  }
  openTelegramModal(data:any = null){
    this.openTelegramModalEmitter.emit(data)
  }
  openNotificationModal(notification:any = null){
    this.openNotificationModalEmitter.emit(notification)
  }
  openVoteForm(data: any){
    this.openVoteFormEmitter.emit(data)
  }
  tempTeamInvitesCount(count: number){
    this.tempTeamInvitesCountEmitter.emit(count)
  }
  teamInvitesCount(count: number){
    this.teamInvitesCountEmitter.emit(count)
  }
  cancelTeamInvite(inviteId: string){
    this.cancelTeamInviteEmitter.emit(inviteId)
  }
  deleteTempTeamInvitation(inviteId: string){
    this.deleteTempTeamInvitationEmitter.emit(inviteId)
  }
  acceptTemporaryInvite(inviteId: string){
    this.acceptTemporaryInviteEmitter.emit(inviteId)
  }
  cancelTempTeamInviteByToUser(inviteId: string){
    this.cancelTempTeamInviteByToUserEmitter.emit(inviteId)
  }
  cancelTeamInviteByToUser(inviteId: string){
    this.cancelTeamInviteByToUserEmitter.emit(inviteId)
  }
  acceptTemporaryInviteByToUser(inviteId: string){
    this.acceptTemporaryInviteByToUserEmitter.emit(inviteId)
  }
  acceptTeamInviteByToUser(inviteId: string){
    this.acceptTeamInviteByToUserEmitter.emit(inviteId)
  }
  deleteChatRoom(roomId: string){
    this.deleteChatRoomEmitter.emit(roomId)
  }
  openChatRoomOptionsModal(data:any){
    this.openChatRoomOptionsModalEmitter.emit(data)
  }
  selectUser(user: any){
    this.selectUserEmitter.emit(user)
  }
  cancelSelectedUser(userId: string){
    this.cancelSelectedUserEmitter.emit(userId)
  }
  closeDropDawnOptions(){
    this.closeDropDawnOptionsEmitter.emit()
  }
  openGameInvitationModal(data: any){
    this.openGameInvitationModalEmitter.emit(data)
  }
  sendInvite(userId: any){
    this.sendInviteEmitter.emit(userId)
  }
  deleteInvite(inviteId: string){
    this.deleteInviteEmitter.emit(inviteId)
  }
  startLoadInviteButtonToUser(userId: any){
    this.startLoadInviteButtonToUserEmitter.emit(userId)
  }
  stopLoadInviteButtonToUser(userId: any){
    this.stopLoadInviteButtonToUserEmitter.emit(userId)
  }

  stageManagementViewInit(): void{
    this.stageManagementViewInitEmitter.emit()
  }

  taskManagementViewInit(): void{
    this.taskManagementModalEmitter.emit()
  }

  openFeedBackModal(data: any): void{
    this.openFeedBackModalEmitter.emit(data)
  }

  openDetailsPopup(data: any): void{
    this.openDetailsPopupEmitter.emit(data)
  }
  openChangeFieldsPopup(data: any): void{
    this.openChangeFieldsPopupEmitter.emit(data)
  }
  updateActionForUserAction(data: any){
    this.updateActionForUserActionEmitter.emit(data)
  }

  profileDataSated(){
    this.profileDataSatedEmitter.emit()
  }

  openPhoneVerifyModal(data: any){
    this.triggerPhoneVerifyModal.emit(data)
  }
  closeNotifyContent(){
    this.closeNotifyContentEmitter.emit()
  }
  chooseInParticipatedTeam(data: any){
    this.chooseInParticipatedTeamEmitter.emit(data)
  }

  scrollToTeamSection(){
    this.scrollToTeamSectionEmitter.emit()
  }

  openCancelEvent(event: any){
    this.openCancelEventEmitter.emit(event)
  }
  showToasty(options: any){
    this.showToastyEmitter.emit(options)
  }

  hideToasty(data: any){
    this.hideToastyEmitter.emit(data)
  }
  changeToastyProgress(data: any){
    this.changeToastyProgressEmitter.emit(data)
  }
  changeOrderStatus(data: any){
    this.changeOrderStatusEmitter.emit(data)
  }
  openOrderDisputeModal(data: any){
    this.openOrderDisputeModalEmitter.emit(data)
  }

  openReportResultModal(participant: any){
    this.openReportResultModalEmiter.emit(participant)
  }
  openParticipateInChallengeModal(data: any){
    this.openParticipateInChallengeModalEmitter.emit(data)
  }
  openChallengeRoomDetailsModal(event: any){
    this.openChallengeRoomDetailsModalEmitter.emit(event);
  }
}

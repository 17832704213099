import {Component, Inject} from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {CommonModule} from "@angular/common";
import {UpdateMetaTags} from "./core/utils/update-meta-tags";
import {filter} from "rxjs";
import {Meta} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ],
  providers: [
      UpdateMetaTags
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(
      private metadata: UpdateMetaTags,
      private router: Router,
      @Inject(DOCUMENT) private dom
  ) {
    this.metadata.updateMetaTags();
    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateCanonicalUrl(environment.portalUrl + '/' +  this.router.url);
    });
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }
}

import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {PreloadAllModules, provideRouter, withPreloading} from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideHttpClient, withFetch} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {provideTranslation} from "./core/config/i18n";
import {provideStore} from "@ngrx/store";
import {ToastrModule} from "ngx-toastr";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideStore(),
    provideHttpClient(withFetch()),
    importProvidersFrom(
        TranslateModule.forRoot(provideTranslation()),
      ToastrModule.forRoot({
        // toast configuration options
        timeOut: 3000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      }),)
  ]
};

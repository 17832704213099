import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {GamerContainerComponent} from "./gamer-container.component";

export const gamerRoutes: Routes = [
  {
    path: '',
    component:  GamerContainerComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./landing/landing.component').then(module => module.LandingComponent)
      },
      {
        path: 'news',
        loadComponent: () => import('./market/market.component').then(module => module.MarketComponent)
      },
      {
        path: 'portfolio',
        loadComponent: () => import('./history/history.component').then(module => module.HistoryComponent)
      },
      {
        path: 'store',
        loadComponent: () => import('./news/news.component').then(module => module.NewsComponent)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(gamerRoutes)],
  exports: [RouterModule]
})
export class GamersRoutingModule { }

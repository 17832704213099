import {Component} from '@angular/core';
import {AuthHeaderComponent} from "../auth-header/auth-header.component";
import {GuestHeaderComponent} from "../guest-header/guest-header.component";
import {NgOptimizedImage} from "@angular/common";
import {ServicesDropdownComponent} from "../../../dropdowns/services-dropdown/services-dropdown.component";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {SafePipe} from "../../../../../shared/pipes/safe.pipe";

@Component({
  selector: 'app-subdomain-player-header',
  standalone: true,
    imports: [
        AuthHeaderComponent,
        GuestHeaderComponent,
        NgOptimizedImage,
        ServicesDropdownComponent,
        TranslateModule,
        RouterLink,
        RouterLinkActive,
        SafePipe
    ],
  providers: [
  ],
  templateUrl: './subdomain-player-header.component.html',
  styleUrl: './subdomain-player-header.component.scss'
})
export class SubdomainPlayerHeaderComponent {
}

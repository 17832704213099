import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {GameContainerComponent} from "../../games/game-pages/game-container.component";

export const organizationRoutes: Routes = [
  {
    path: '',
    component:  GameContainerComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./landing/landing.component').then(module => module.LandingComponent)
      },
      {
        path: 'news',
        loadComponent: () => import('./market/market.component').then(module => module.MarketComponent)
      },
      {
        path: 'teams',
        loadComponent: () => import('./teams/teams.component').then(module => module.TeamsComponent)
      },
      {
        path: 'tournaments',
        loadComponent: () => import('./tournaments/tournaments.component').then(module => module.TournamentsComponent)
      },
      {
        path: 'players',
        loadComponent: () => import('./players/players.component').then(module => module.PlayersComponent)
      },
      {
        path: 'store',
        loadComponent: () => import('./news/news.component').then(module => module.NewsComponent)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(organizationRoutes)],
  exports: [RouterModule]
})
export class OrganizationSubdomainRoutingModule { }

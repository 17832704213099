import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TeamsContainerComponent} from "./teams-container.component";

export const teamRoutes: Routes = [
  {
    path: '',
    component:  TeamsContainerComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./landing/landing.component').then(module => module.LandingComponent)
      },
      {
        path: 'history',
        loadComponent: () => import('./history/history.component').then(module => module.HistoryComponent)
      },
      {
        path: 'news',
        loadComponent: () => import('./news/news.component').then(module => module.NewsComponent)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(teamRoutes)],
  exports: [RouterModule]
})
export class TeamsSubdomainRoutingModule { }

import { Injectable } from '@angular/core';
function _window(): any {
  if(typeof window !== "undefined")
    return window;
  return null;
}
@Injectable({providedIn: 'root'})
export class WindowRef {
  //@ts-ignore
  get nativeWindow(): any {
    return _window();
  }
}

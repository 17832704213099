import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {WindowRef} from "../helpers/windowRef.service";
import {isPlatformBrowser} from "@angular/common";
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  static platformId: any;
  static windowRef: WindowRef;
  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private windowRef: WindowRef) {
    StorageService.platformId = platformId;
    StorageService.windowRef = windowRef;
  }

  static statics = {
    'cache': {
      'regions': 'regions',
      'translationDefaultModel': 'translationDefaultModel'
    },
    currentUser: 'currentUser',
    tournamentDraftData: 'tournamentDraftData',
    challengeDraftData: 'challengeDraftData',
    profileData: 'profileData',
    publisher: 'publisher',
    adSense: 'adSense',
    yillAds: 'yillAds',
    buildBlock: 'buildBlock',
    lastPage: 'lastPage',
    myOnboardingTeam: 'myOnboardingTeam',
    lastAction: 'lastAction',
    guestUser: 'guestUser',
    token: 'token',
    referral: 'referral',
    lang: 'lang',
    soundEnabled: 'soundEnabled',
    currency: 'currency',
    initialInfo: 'initialInfo',
    ticketPrice: 'ticketPrice',
    pTicketPrice: 'pTicketPrice',
    chatActiveRoomIds: 'chatActiveRoomIds',
    openDiscordServerList: 'openDiscordServerList',
  };

  static getItem(name:string): any {
    if(typeof window !== 'undefined') {
      return localStorage.getItem(name);
    }
  }
  static getJsonItem(name:string): any {
      let item = StorageService.getItem(name);
      try {
        return (name)?JSON.parse(item):null;
      } catch (e) {
        return null;
      }


  }

  static getToken(): any {
    return StorageService.getItem(StorageService.statics.token);
  }

  static setItem(name:string, value:any): any {
    if(typeof window !== 'undefined') {
      return localStorage.setItem(name, value);
    }

  }

  static setJsonItem(name:string, obj:any): any {
    StorageService.setItem(name, JSON.stringify(obj));
  }

  static removeItem(name:string): any {
    return localStorage.removeItem(name);
  }
  static clear(){
    return localStorage.clear();
  }
}

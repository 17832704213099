import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {
  SubdomainGameHeaderComponent
} from "../../../../../components/navigations/header/subdomain-game-header/subdomain-game-header.component";
import {HttpClientModule} from "@angular/common/http";
import {HttpService} from "../../../../../../core/services/http.service";
@Component({
  selector: 'app-gamer-container',
  standalone: true,
  imports: [
    RouterOutlet,
    HttpClientModule,
    SubdomainGameHeaderComponent
  ],
  providers: [
    HttpService
  ],
  templateUrl: './game-container.component.html',
  styleUrl: './game-container.component.scss'
})
export class GameContainerComponent {

}


import { Routes } from '@angular/router';
import {AuthGuard} from "./core/guards/auth.guard";
export const routes: Routes = [
  {
    path: 'publisher/:publisherId',
    canActivate: [AuthGuard],
    loadComponent: () => import('./old-ui/theme/layout/auth/publisher/publisher-layout.component').then(module => module.PublisherLayoutComponent),
    loadChildren: () => import('./old-ui/theme/layout/auth/publisher/publisher-layout-routing.module').then(m => m.PublisherLayoutRoutingModule)
  },
  {
    path: 'subdomain',
    loadChildren: () => import('./new-ui/layouts/subdomain/subdomain-routing.module').then(m => m.SubdomainRoutingModule)
  },
  {
    path: '',
    loadChildren: () => import('./new-ui/layouts/cybfed/cybfed-routing.module').then(m => m.CybfedRoutingModule)
  },
  // { path: '**', redirectTo: '/', pathMatch: 'full' }
];


<header class="w-full relative bg-cover bg-no-repeat h-[300px]" style="background-image: url('/assets/image/uc-tournament-banner.jpg'); background-size: cover;">
<!--  <nav class="mx-auto relative z-10 container flex items-center justify-between p-3 lg:px-6" aria-label="Global">-->
<!--    <div class="md:flex mr-[36px] hidden">-->
<!--      <a [routerLink]="['/']" class="-m-1.5 p-1.5">-->
<!--        <span class="sr-only">CybFed ESports/CyberSport Arena</span>-->
<!--        <img loading="lazy"  class="w-[56px] h-auto" [ngSrc]="'/assets/image/cybfed-logo.png' | safe: 'url'" width="77" height="53" alt="CybFed logo" priority>-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="flex max-w-7xl flex-end md:mr-6">-->
<!--      <div class="flex md:gap-x-5 md:justify-start ml-3 text-center">-->
<!--        <a [routerLink]="['/']" [routerLinkActive]="['text-primary']"  [routerLinkActiveOptions]="{ exact:true }" class="text-sm font-semibold text-white leading-6 text-center p-2">-->
<!--          <div class="block">-->
<!--            {{'home' | translate}}-->
<!--          </div>-->
<!--        </a>-->
<!--        <a [routerLink]="['/tournaments']" [routerLinkActive]="['text-primary']"  [routerLinkActiveOptions]="{ exact:true }" class="text-sm font-semibold text-white leading-6 text-center p-2">-->
<!--           <div class="block">-->
<!--              {{'tournaments' | translate}}-->
<!--           </div>-->
<!--        </a>-->
<!--        <a [routerLink]="['/challenges']" [routerLinkActive]="['text-primary']" class="text-sm font-semibold leading-6 text-white p-2">-->
<!--          <div class="block">-->
<!--            {{'battles' | translate}}-->
<!--          </div>-->
<!--        </a>-->
<!--        <a [routerLink]="['/leaderboards']" [routerLinkActive]="['text-primary']" class="text-sm font-semibold leading-6 text-white p-2">-->
<!--          <div class="block">-->
<!--              {{'leaderboards' | translate}}-->
<!--          </div>-->
<!--        </a>-->
<!--&lt;!&ndash;        <a [routerLink]="['/challenges']" [routerLinkActive]="['text-primary']" class="text-sm  font-semibold leading-6 text-white p-2">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="block">&ndash;&gt;-->
<!--&lt;!&ndash;            {{'news' | translate}}&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;        <a [routerLink]="['/marketplace']" [routerLinkActive]="['text-primary']" class="text-sm  font-semibold leading-6 text-white p-2">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="block">&ndash;&gt;-->
<!--&lt;!&ndash;            {{'store' | translate}}&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </nav>-->
  <div class="header-mask absolute top-0 left-0 w-full h-full"></div>
<!--  <div class="flex justify-end mx-2 my-4 z-10 relative text-right gap-x-8">-->
<!--    <div>-->
<!--      <img loading="lazy"  src="assets/image/cybfed-logo.png" class="h-14"  alt=""/>-->
<!--    </div>-->
<!--    <div>-->
<!--      <img loading="lazy"  src="assets/flags/en.ico" class="h-14" />-->
<!--    </div>-->
<!--  </div>-->
</header>

import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {
  SubdomainPlayerHeaderComponent
} from "../../../../../components/navigations/header/subdomain-player-header/subdomain-player-header.component";
import {
  SubdomainTeamHeaderComponent
} from "../../../../../components/navigations/header/subdomain-team-header/subdomain-team-header.component";
import {HttpClientModule} from "@angular/common/http";
import {HttpService} from "../../../../../../core/services/http.service";
import {TeamsService} from "../../../../../../core/services/teams.service";

@Component({
  selector: 'app-teams-container',
  standalone: true,
  imports: [
    RouterOutlet,
    HttpClientModule,
    SubdomainPlayerHeaderComponent,
    SubdomainTeamHeaderComponent
  ],
  providers: [
    HttpService,
    TeamsService
  ],
  templateUrl: './teams-container.component.html',
  styleUrl: './teams-container.component.scss'
})
export class TeamsContainerComponent {

}

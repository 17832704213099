import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  private isBrowser: boolean;
  private hostname: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject('HOSTNAME') hostname: string) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.hostname = hostname; // Use injected hostname for SSR
  }

  getDomain(): string | null {
    const hostname = this.isBrowser ? window.location.hostname : this.hostname;
    if (!hostname) return 'cybfed.com';

    if (hostname.includes('esp.am')) {
      return 'esp.am';
    } else if (hostname.includes('esp.team')) {
      return 'esp.team';
    } else {
      return 'cybfed.com';
    }
  }

  getSubdomain(): string | null {
    const hostname = this.isBrowser ? window.location.hostname : this.hostname;
    if (!hostname) return null;

    const parts = hostname.split('.');
    if (parts.length > 2) {
      // Assume the first part is the subdomain
      return parts[0];
    }
    return null;
  }
}

import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, PLATFORM_ID} from '@angular/core';
import {
    InMemoryScrollingFeature, InMemoryScrollingOptions,
    provideRouter,
    withInMemoryScrolling,
} from '@angular/router';

import { routes } from './app.routes';
import { gameRoutes } from './new-ui/pages/functional/social/games/game-pages/game-subdomain-routing.module';
import { gamerRoutes }  from './new-ui/pages/functional/social/gamers/gamer-pages/gamer-subdomain-routing.module';
import { teamRoutes }  from './new-ui/pages/functional/social/teams/teams-pages/teams-subdomain-routing.module';
import { organizationRoutes } from './new-ui/pages/functional/social/organizations/organization-pages/organization-subdomain-routing.module';
import { provideClientHydration } from '@angular/platform-browser';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideHttpClient, withFetch} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {provideTranslation} from "./core/config/i18n";
import {provideStore} from "@ngrx/store";
import {ToastrModule} from "ngx-toastr";
import {DomainService} from "./core/utils/domain-service";
import {StorageService} from "./core/services/storage.service";

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};


// Factory function to determine routes based on subdomain
function getRoutes(platformId: Object): any {
    const domainService = new DomainService(platformId, (typeof window !== 'undefined' ? window.location.hostname : ''));
    const subdomain = domainService.getSubdomain();
    let domain = subdomain ? domainService.getDomain() : null;
    console.log('subdomain' + domainService.getSubdomain(), 'domain' + domainService.getDomain());
    StorageService.removeItem(StorageService.statics.subdomain);
    switch (domain) {
        case 'esp.am':
            StorageService.setItem(StorageService.statics.subdomain, subdomain);
            return gamerRoutes;
        case 'esp.team':
            StorageService.setItem(StorageService.statics.subdomain, subdomain);
            return teamRoutes;
        // case 'cybfed.com':
        //     return routes;
        case 'organizations':
            return organizationRoutes;
        default:
            return routes; // Default routing for main domain or unknown subdomains
    }
}

const inMemoryScrollingFeature: InMemoryScrollingFeature =
    withInMemoryScrolling(scrollConfig);
export const appConfig: ApplicationConfig = {
  providers: [
      {
          provide: 'HOSTNAME',
          useFactory: () => (typeof window !== 'undefined' ? window.location.hostname : ''), // For browser environment
      },
      {
          provide: DomainService,
          useFactory: (platformId: Object, hostname: string) => new DomainService(platformId, hostname),
          deps: [PLATFORM_ID],
      },
    provideRouter(getRoutes(PLATFORM_ID), inMemoryScrollingFeature),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideStore(),
    provideHttpClient(withFetch()),
    importProvidersFrom(
        TranslateModule.forRoot(provideTranslation()),
      ToastrModule.forRoot({
        // toast configuration options
        timeOut: 3000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      }),), provideClientHydration()
  ]
}